






































import { Component, Vue, Prop } from "vue-property-decorator";
// @ts-ignore
import _ from "lodash";
import {roleApi} from "@/api/role";

@Component
export default class RoleSelect extends Vue {
  @Prop({ required: true }) value!: Array<number> | number;
  @Prop({ required: true }) enterpriseId!: number;
  @Prop({ default: false }) multiple!: boolean;
  @Prop({ default: "" }) width!: string; // 需要带参数，如100px
  @Prop({ default: "normal" }) size!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: 0 }) multipleLimit!: number;
  @Prop({ default: "请输入关键词" }) placeholder!: string;
  @Prop({ default: false }) preview!: boolean;

  options: Array<any> = [];
  private loading = false;

  get localValue(): Array<number> | number | null {
    return this.value;
  }

  set localValue(newVal: Array<number> | number | null) {
    this.$emit("input", newVal);
  }

  get previewList() {
    const selectList = Array.isArray(this.value) ? this.value : [this.value];
    return this.options.filter((i) => selectList.includes(i.id));
  }

  created() {
    this.remoteSearch('')
    // if (this.value && Array.isArray(this.value)) {
    //   this.getValueItems(this.value);
    // } else if (this.value) {
    //   this.getValueItems([this.value]);
    // }
  }

  private async getValueItems(ids: Array<number>) {
    // 获取已经被选择的列表
    // const employees = await DepartmentModule.StrictFetch(ids)
    // this.options = set([...employees, ...this.options], val => val.id)
    // this.localValue = ids
  }

  private focusSearch() {
    if (!this.value) {
      this.remoteSearch("");
    }
  }

  private async remoteSearch(value: string) {
    // const enableSearch = value !== "" || !this.localValue;
    // if (enableSearch) {
    this.loading = true;
    try {
      const result = await roleApi.getRoles({
        name: value,
        enterpriseId: this.enterpriseId,
        page: 1,
        perPage: 99999,
      });
      this.options = result.items;
    } finally {
      this.loading = false;
    }
    // }
  }

  get _style() {
    return `width: ${this.width || "auto"}`;
  }
}
